
import qs from "query-string";
import { defineComponent, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useI18n } from "vue-i18n";
import store from "@/store";
import { Order, Line } from "@/core/config/tables";
import config from "@/core/config/UtilConfig";
import supplyExternal from "../scripts/supplyExternal.js";

export default defineComponent({
  name: "orderLines-listing",
  components: {
    Datatable,
  },
  setup() {
    const util = config.util;
    const { t, te } = useI18n();
    
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(translate("lastOrders"), []);
    });

    return { 
      translate,
      util
    };
  },
  data() {
    return {
      currentOrder: {} as Order,
      orders: Array<Order>(),
      orderLines: Array<Line>(),
      key: "init",
      orderHeaders: config.orderHeaders,
      headers: config.lineHeaders,
      orderPage: 1,
      linePage: 1,
      pageSize: 20,
      orderId: 0,
      totalCount: 0,
      showPrevButton: false,
      showNextButton: false,
      statuses: config.defaultStatuses,
      field: config.lineSort,
      order: "asc"
    }
  },
  beforeMount(){
    this.getOrder(this.field, this.order);
  },
  mounted() {
    window.addEventListener("locale-changed", (event) => {
      setCurrentPageBreadcrumbs(this.translate("lastOrders"), []);
      this.key = "lang"+localStorage.getItem("lang");
    });
    this.getNextOrderLines();
  },
  methods: {
    getOrder: function(field, order) {
      
      supplyExternal.methods.getIP(function(ip){return})
      setTimeout(() => {
        const params = { params: {ip: store.getters.getIP, util: config.util, status: this.statuses, page: this.orderPage, pageSize: 1, sort: "code:desc"},
          paramsSerializer: params => {
            return qs.stringify(params);
          } 
        };
        axios.get(config.host+"/o/headless-supply-box/v1.0/orders", params).
          then(response => {
            if(response.data.items[0]) {
              this.currentOrder = response.data.items[0];
              this.orderId = response.data.items[0].id;
              this.totalCount = response.data.totalCount;
              this.field = field;
              this.order = order;
              this.linePage = 1;
              
              const params = { params: {ip: store.getters.getIP, util: config.util, page: this.linePage, pageSize: this.pageSize, sort: this.field+":"+this.order} };
              axios.get(config.host+"/o/headless-supply-box/v1.0/orders/"+this.orderId+"/lines", params).
                then(response => {
                  if(response.data.items[0]) {
                    this.orderLines = response.data.items;
                    this.linePage += 1;
                    this.key = "loadLines"+this.orderId+this.field+this.order;;
                  }
                  else {
                    this.orderLines = Array<Line>();
                    this.key = "empty";  
                  }
                }).catch(e => {
                  this.orderLines = Array<Line>();
                  this.key = "empty";
                });

              if(this.orderPage > 1) this.showPrevButton = true;
              else if(this.orderPage == 1) this.showPrevButton = false;
            
              if(this.orderPage < this.totalCount) this.showNextButton = true;
              else if(this.orderPage == this.totalCount) this.showNextButton = false;

              this.key = "loadOrder"+this.orderId;
            }
            else {
              this.currentOrder = {} as Order;
              this.orderLines = Array<Line>();
              this.showPrevButton = false;
              this.showNextButton = false;
              this.key = "empty";
            }
          }).catch(e => {
            this.currentOrder = {} as Order;
            this.orderLines = Array<Line>();
            this.showPrevButton = false;
            this.showNextButton = false;
            this.key = "empty";
          });
        }, 500);
    },
    getNextOrderLines: function() {
      window.onscroll = () => {

        let bottomOfWindow = (window.innerHeight + document.documentElement.scrollTop) === document.documentElement.scrollHeight;

        if (bottomOfWindow) {
          supplyExternal.methods.getIP(function(ip){return})
          setTimeout(() => {
            const params = { params: {ip: store.getters.getIP, util: config.util, page: this.linePage, pageSize: this.pageSize, sort: this.field+":"+this.order} };
              axios.get(config.host+"/o/headless-supply-box/v1.0/orders/"+this.orderId+"/lines", params).
                then(response => {
                  for(var i= 0; i<response.data.items.length; i++) {
                    this.orderLines.push(response.data.items[i]);
                  }
                  this.linePage += 1;
                  this.key = "nextLines"+this.linePage;
                }).catch(e => console.log(e));
            }, 500);
          }
      }
    },
    getPrevOrder: function() {
      if(this.orderPage > 1) this.orderPage -= 1;
      this.getOrder(this.field, this.order);
    },
    getNextOrder: function() {
      if(this.orderPage < this.totalCount) this.orderPage += 1;
      this.getOrder(this.field, this.order);
    },
    changeStatus: function(e){
      if(e.target.value == 1){
        this.statuses = ["Suministrado"];
      } else if(e.target.value == 2) {
        this.statuses = ["Pte Suministro"];
      } else {
        this.statuses = config.defaultStatuses;
      }

      this.orderPage = 1;
      this.getOrder(this.field, this.order);
    }
  },
});
